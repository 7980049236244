import { Box, Divider } from '@material-ui/core';
import { Button } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { UiCountyCmsFilters } from './CountyCmsFilters.style';
import KeywordFilter from './KeywordFilter';
import DateFilter from './DateFilter';
import { v4 as uuidV4 } from 'uuid';

const METHOD_FILTER_COMPONENT_MAP = {
  keyword: KeywordFilter,
  date: DateFilter
};

export const CountyCmsFilters = ({
  methods = ['keyword', 'date'],
  keywordFilterProp,
  dateFilterProp,
  onSearch,
}) => {
  const methodPropMap = {
    keyword: keywordFilterProp,
    date: dateFilterProp
  };
  const filters = methods.map(method => ({
    id: uuidV4,
    Component: METHOD_FILTER_COMPONENT_MAP[method],
    prop: methodPropMap[method]
  }));
  const clickSearch = () => {
    let searchParam;
    if (keywordFilterProp) {
      searchParam = {
        ...searchParam,
        searchCondition: keywordFilterProp.searchCondition,
        keyword: keywordFilterProp.keyword,
      };
    }
    if (dateFilterProp) {
      searchParam = {
        ...searchParam,
        startAt: dateFilterProp.startAt,
        endAt: dateFilterProp.endAt,
      };
    }
    onSearch(searchParam);
  };
  return (
    <UiCountyCmsFilters>
      {filters.map((filter, index) => {
        const isLast = index === filters.length - 1;
        return (
          <Box key={filter.id} className="filter-component-box">
            <filter.Component {...filter.prop} />
            {!isLast && (
              <Box className="divider-box">
                <Divider orientation="vertical" flexItem />
              </Box>
            )}
          </Box>
        );
      })}
      <Box className="search-button-box">
        <Button fullWidth onClick={clickSearch}>
          搜尋
        </Button>
      </Box>
    </UiCountyCmsFilters>
  );
};

CountyCmsFilters.propTypes = {
  methods: PropTypes.array,
  keywordFilterProp: PropTypes.shape(),
  dateFilterProp: PropTypes.shape(),
  onSearch: PropTypes.func,
};
