const SEARCH_CONDITION_OPTION_VALUE = {
  COUNTY: 'county',
  CITY: 'city',
  SCHOOL_NAME: 'schoolName',
};

export const SEARCH_CONDITION_API_KEY_MAP = {
  [SEARCH_CONDITION_OPTION_VALUE.COUNTY]: 'cityName',
  [SEARCH_CONDITION_OPTION_VALUE.CITY]: 'districtName',
  [SEARCH_CONDITION_OPTION_VALUE.SCHOOL_NAME]: 'officialName',
};

export const SEARCH_CONDITION_OPTIONS = [
  {
    label: '縣市',
    value: 'county'
  },
  {
    label: '鄉鎮市區',
    value: 'city'
  },
  {
    label: '學校名稱',
    value: 'schoolName'
  }
];