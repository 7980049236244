import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { useCountyCms } from 'store/countyCms/index';
import { UiTableContainer } from './CountyCmsOrganizationsTable.style';

const TABLE_HEAD_CELLS = [
  {
    name: '機構名稱'
  },
  {
    name: '班級總數'
  },
  {
    name: '教師人數'
  },
  {
    name: '學生人數'
  },
  {
    name: '教學資源'
  }
];

function generateLatestCreatedTimeText({ organization, field }) {
  const latestCreatedAt = organization[field].latestCreatedAt;
  const latestCreatedTime = latestCreatedAt ? format(latestCreatedAt, 'yyyy/MM/dd') : '-';
  let latestCreatedTimeText;
  switch (field) {
    case 'group':
      latestCreatedTimeText = `最新創立時間 ${latestCreatedTime}`;
      break;
    case 'staff':
    case 'customer':
      latestCreatedTimeText = `最新加入時間 ${latestCreatedTime}`;
      break;
    case 'resource':
    default:
      latestCreatedTimeText = `最新上傳時間 ${latestCreatedTime}`;
      break;
  }
  return latestCreatedTimeText;
}

/**
 * 學習報告 - 機構總覽表格
 */
export const CountyCmsOrganizationsTable = () => {
  const [{ summaryBoardApiData }] = useCountyCms();
  const organizations = useMemo(() => {
    const orgs = summaryBoardApiData.organizations.map(organization => {
      const group = {
        ...organization.group,
        latestCreatedTimeText: generateLatestCreatedTimeText({
          organization,
          field: 'group'
        })
      };
      const staff = {
        ...organization.staff,
        latestCreatedTimeText: generateLatestCreatedTimeText({
          organization,
          field: 'staff'
        })
      };
      const customer = {
        ...organization.customer,
        latestCreatedTimeText: generateLatestCreatedTimeText({
          organization,
          field: 'customer'
        })
      };
      const resource = {
        ...organization.resource,
        latestCreatedTimeText: generateLatestCreatedTimeText({
          organization,
          field: 'resource'
        })
      };
      return {
        ...organization,
        group,
        staff,
        customer,
        resource
      };
    });
    return orgs;
  }, [summaryBoardApiData]);
  return (
    <UiTableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {TABLE_HEAD_CELLS.map(CELL => {
              return (
                <TableCell key={CELL.name} align="left" scope="column">
                  {CELL.name}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {organizations.map(organization => {
            return (
              <TableRow key={organization.id}>
                <TableCell>
                  <Typography>{organization.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography className="first-text">{organization.group.total}</Typography>
                  <Typography className="second-text">
                    {organization.group.latestCreatedTimeText}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="first-text">{organization.staff.total}</Typography>
                  <Typography className="second-text">
                    {organization.staff.latestCreatedTimeText}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="first-text">{organization.customer.total}</Typography>
                  <Typography className="second-text">
                    {organization.customer.latestCreatedTimeText}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="first-text">{organization.resource.total}</Typography>
                  <Typography className="second-text">
                    {organization.resource.latestCreatedTimeText}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </UiTableContainer>
  );
};

CountyCmsOrganizationsTable.propTypes = {};
