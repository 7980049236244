import { Box, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  Button,
  CountyCmsToggleButton,
  CountyCmsFilters,
  CountyCmsStudentsTable,
  TablePagination
} from 'components';
import { GRADE_CHINESE_CHAR_MAP } from 'constants/index';
import { ORGAN_GROUP_ID, RECORD_TYPE } from 'constants/countyCms';
import { endOfYesterday, format, millisecondsToMinutes, millisecondsToSeconds, sub } from 'date-fns';
import queryString from 'query-string';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getCountyExcel } from 'services/api/organGroups';
import { useCountyCms } from 'store/countyCms';
import { FILE_EXTENSION_MAP, downloadFile } from 'utils/downloadFile';
import { useAlert } from 'utils/hooks/useAlert';
import {
  UiToggleButtonBox,
  UiActionsBox,
  UiTableBox,
  UiTablePaginationBox
} from './Students.style';
import {
  LEARNING_REPORT_SCHOOLS_URL,
  SEARCH_CONDITION_API_KEY_MAP,
  SEARCH_CONDITION_OPTION,
  TOGGLE_BUTTONS,
  UNIT
} from './Students.constant';

const INITIAL_END_AT = endOfYesterday().getTime();
const INITIAL_START_AT = sub(endOfYesterday(), { years: 1 }).getTime();
let controller = new AbortController();

/**
 * 學習報告 - 學生個人紀錄
 */
export const Students = () => {
  const isAbortedRef = useRef(false);
  const history = useHistory();
  const location = useLocation();
  const qs = queryString.parse(location.search);
  const { setAlert } = useAlert();
  const [
    { examRecordsApiData, viewRecordsApiData },
    { getExamRecordsFromApi, getViewRecordsFromApi }
  ] = useCountyCms();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDownloadExcel, setIsLoadingDownloadExcel] = useState(false);
  const [unit, setUnit] = useState(qs.unit || UNIT.TEST);
  const [filter, setFilter] = useState({
    searchCondition: SEARCH_CONDITION_OPTION[unit][0].value,
    keyword: '',
    startAt: INITIAL_START_AT,
    endAt: INITIAL_END_AT
  });
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    totalCount: 0
  });
  const searchConditionOptions = SEARCH_CONDITION_OPTION[unit];

  const students = useMemo(() => {
    let students = [];
    switch (unit) {
      case UNIT.TEST:
        students = examRecordsApiData.records.map(record => {
          const grade = record.grades
            .map(grade => GRADE_CHINESE_CHAR_MAP[grade])
            .join('、');
          const testTime = `${record.period}分鐘`;
          const submissionTime = record?.resultTime?.resultAt ? format(record.resultTime.resultAt, 'yyyy/MM/dd HH:mm') : '';
          const answerDuration = {
            minute: record?.resultTime?.sumTime ? millisecondsToMinutes(record.resultTime.sumTime) : null,
            second: record?.resultTime?.sumTime ? millisecondsToSeconds(record.resultTime.sumTime) : null,
          };
          const answerDurationText = answerDuration.second ? `${answerDuration.minute}:${answerDuration.second}` : null;
          const account = record.userId;
          const examResultUrl = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/user/report/${record.examId}/${account}`;
          return {
            county: record.cityName,
            city: record.districtName,
            schoolName: record.officialName,
            classroomName: record.className,
            grades: grade,
            seatNumber: record.seatNumber,
            account,
            name: record.userName,
            assigningTeacher: record.teacherName,
            testName: record.examName,
            testTime,
            submissionTime,
            answerDurationText,
            testScore: record.score,
            testResult: (
              <IconButton
                style={{ background: '#31819314', color: '#318193' }}
                onClick={() => window.open(examResultUrl, '_blank')}
              >
                <VisibilityIcon />
              </IconButton>
            )
          };
        });
        break;
      case UNIT.VIDEO:
      default:
        students = viewRecordsApiData.records.map(record => {
          const grade = record.grades
            .map(grade => GRADE_CHINESE_CHAR_MAP[grade])
            .join('、');
          const teacherAssignedTime = record.addAt ? format(record.addAt, 'yyyy/MM/dd HH:mm') : '學生自學';
          const studentViewTime = record.viewAt ? format(record.viewAt, 'yyyy/MM/dd HH:mm') : '';
          return {
            county: record.cityName,
            city: record.districtName,
            schoolName: record.organizationName,
            classroomName: record.className,
            grade,
            seatNumber: record.seatNumber,
            account: record.studentId,
            name: record.studentName,
            assigningTeacher: record.teacherName,
            videoSubject: record.sub,
            videoName: record.videoName,
            year: record.year,
            education: record.school,
            volumeNumber: record.vol,
            version: record.version,
            teacherAssignedTime,
            studentViewTime
          };
        });
        break;
    }
    return students;
  }, [examRecordsApiData, viewRecordsApiData]);

  /* 取得學生個人紀錄 - 測驗完成紀錄 */
  const fetchExamRecords = async params => {
    controller.abort();
    controller = new AbortController();
    const signal = controller.signal;
    const skip = pagination.page * pagination.rowsPerPage;
    const limit = pagination.rowsPerPage;
    const keywordFilter = {
      [SEARCH_CONDITION_API_KEY_MAP[filter.searchCondition]]: filter.keyword
    };
    const newParams = {
      organGroupId: ORGAN_GROUP_ID.KAOHSIUNG_CITY,
      skip,
      limit,
      startAt: filter.startAt,
      endAt: filter.endAt,
      ...(filter.keyword && keywordFilter),
      ...params
    };
    const { status, data } = await getExamRecordsFromApi({
      params: newParams,
      signal,
    });
    if (status === 'success') {
      setPagination(prev => ({
        ...prev,
        totalCount: data.total
      }));
    } else if (status === 'failure') {
      throw new Error('取得學校使用紀錄失敗');
    } else if (status === 'aborted') {
      isAbortedRef.current = true;
    }
  };

  /* 取得學生個人紀錄 - 影音觀看紀錄 */
  const fetchViewRecords = async params => {
    controller.abort();
    controller = new AbortController();
    const signal = controller.signal;
    const skip = pagination.page * pagination.rowsPerPage;
    const limit = pagination.rowsPerPage;
    const keywordFilter = {
      [SEARCH_CONDITION_API_KEY_MAP[filter.searchCondition]]: filter.keyword
    };
    const newParams = {
      organGroupId: ORGAN_GROUP_ID.KAOHSIUNG_CITY,
      skip,
      limit,
      startAt: filter.startAt,
      endAt: filter.endAt,
      ...(filter.keyword && keywordFilter),
      ...params
    };
    const { status, data } = await getViewRecordsFromApi({
      params: newParams,
      signal
    });
    if (status === 'success') {
      setPagination(prev => ({
        ...prev,
        totalCount: data.total
      }));
    } else if (status === 'failure') {
      throw new Error('取得學校使用紀錄失敗');
    } else if (status === 'aborted') {
      isAbortedRef.current = true;
    }
  };

  /* 選擇要打的 API */
  const selectApi = async ({ unit, params }) => {
    switch (unit) {
      case UNIT.TEST:
        await fetchExamRecords(params);
        break;
      case UNIT.VIDEO:
      default:
        await fetchViewRecords(params);
        break;
    }
  };

  /* click 學校、班級 */
  const clickToggleButton = (event, newUnit) => {
    if (newUnit === null) return;
    setUnit(newUnit);
    setPagination(prev => ({
      ...prev,
      page: 0
    }));
    setFilter(prev => ({
      ...prev,
      searchCondition: SEARCH_CONDITION_OPTION[newUnit][0].value,
      keyword: ''
    }));
    const url = `${LEARNING_REPORT_SCHOOLS_URL}?unit=${newUnit}`;
    history.push(url);
  };

  /* 改每頁列數 */
  const changeRowsPerPage = async event => {
    try {
      setIsLoading(true);
      const rowsPerPage = parseInt(event.target.value, 10);
      setPagination(prev => ({
        ...prev,
        page: 0,
        rowsPerPage
      }));
      const params = {
        skip: 0,
        limit: rowsPerPage
      };
      await selectApi({ unit, params });
    } catch (error) {
      const errMsg = error?.message || '程式錯誤';
      setAlert(errMsg, 'error');
    } finally {
      isAbortedRef.current ? isAbortedRef.current = false : setIsLoading(false);
    }
  };

  /* 切上下頁 */
  const changePage = async (event, newPage) => {
    try {
      setIsLoading(true);
      setPagination(prev => ({
        ...prev,
        page: newPage
      }));
      const params = {
        skip: newPage * pagination.rowsPerPage,
        limit: pagination.rowsPerPage
      };
      await selectApi({ unit, params });
    } catch (error) {
      const errMsg = error?.message || '程式錯誤';
      setAlert(errMsg, 'error');
    } finally {
      isAbortedRef.current ? isAbortedRef.current = false : setIsLoading(false);
    }
  };

  /* change 搜尋條件 */
  const handleChangeSelect = event => {
    const searchCondition = event.target.value;
    setFilter(prev => ({
      ...prev,
      keyword: '',
      searchCondition
    }));
  };

  /* change 搜尋關鍵字 */
  const handleChangeKeyword = event => {
    const keyword = event.target.value;
    setFilter(prev => ({
      ...prev,
      keyword
    }));
  };

  /* change 起始日期 */
  const handleChangeStartAt = date => {
    const startAt = new Date(date).getTime();
    setFilter(prev => ({
      ...prev,
      startAt
    }));
  };

  /* change 結束日期 */
  const handleChangeEndAt = date => {
    const endAt = new Date(date).getTime();
    setFilter(prev => ({
      ...prev,
      endAt
    }));
  };

  /* 搜尋 */
  const handleSearch = async searchParam => {
    try {
      setIsLoading(true);
      setPagination(prev => ({
        ...prev,
        page: 0
      }));
      setFilter(searchParam);
      const keywordFilter = {
        [SEARCH_CONDITION_API_KEY_MAP[filter.searchCondition]]: filter.keyword
      };
      const params = {
        skip: 0,
        limit: pagination.rowsPerPage,
        startAt: searchParam.startAt,
        endAt: searchParam.endAt,
        ...(searchParam.keyword && keywordFilter)
      };
      await selectApi({ unit, params });
    } catch (error) {
      const errMsg = error?.message || '程式錯誤';
      setAlert(errMsg, 'error');
    } finally {
      isAbortedRef.current ? isAbortedRef.current = false : setIsLoading(false);
    }
  };

  /* 取得 excel 資料 */
  const fetchExcelData = async () => {
    const recordType = unit === UNIT.TEST ? RECORD_TYPE.EXAM_RECORD : RECORD_TYPE.VIEW_RECORD;
    const keywordFilter = {
      [SEARCH_CONDITION_API_KEY_MAP[filter.searchCondition]]: filter.keyword
    };
    const skip = pagination.page * pagination.rowsPerPage;
    const params = {
      organGroupId: ORGAN_GROUP_ID.KAOHSIUNG_CITY,
      recordType,
      skip,
      startAt: filter.startAt,
      endAt: filter.endAt,
      ...(filter.keyword && keywordFilter)
    };
    const { isSuccess, data } = await getCountyExcel(params);
    if (isSuccess) {
      return data;
    } else {
      throw new Error('取得 excel 資料失敗');
    }
  };

  /* 匯出表單 */
  const downloadExcel = async () => {
    try {
      setIsLoadingDownloadExcel(true);
      const { excelData, fileName } = await fetchExcelData();
      const buffer = excelData[1].data;
      const newFilename = `${fileName}.${FILE_EXTENSION_MAP.XLSX}`;
      await downloadFile({ buffer, fileName: newFilename, type: FILE_EXTENSION_MAP.XLSX });
    } catch (error) {
      const errMsg = error?.message || '匯出表單失敗';
      setAlert(errMsg, 'error');
    } finally {
      setIsLoadingDownloadExcel(false);
    }
  };

  const keywordFilterProp = {
    searchConditionOptions: searchConditionOptions,
    searchCondition: filter.searchCondition,
    keyword: filter.keyword,
    onChangeSelect: handleChangeSelect,
    onChangeKeyword: handleChangeKeyword
  };
  const dateFilterProp = {
    startAt: filter.startAt,
    endAt: filter.endAt,
    onChangeStartAt: handleChangeStartAt,
    onChangeEndAt: handleChangeEndAt
  };

  const fetchData = async unit => {
    try {
      setIsLoading(true);
      await selectApi({ unit });
    } catch (error) {
      const errMsg = error?.message || '程式錯誤';
      setAlert(errMsg, 'error');
    } finally {
      isAbortedRef.current ? isAbortedRef.current = false : setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(unit);
    return () => controller.abort();
  }, [unit]);

  return (
    <Box>
      <UiToggleButtonBox>
        <CountyCmsToggleButton
          unit={unit}
          buttons={TOGGLE_BUTTONS}
          onChange={clickToggleButton}
        />
      </UiToggleButtonBox>
      <UiActionsBox>
        <CountyCmsFilters
          methods={['keyword', 'date']}
          keywordFilterProp={keywordFilterProp}
          dateFilterProp={dateFilterProp}
          onSearch={handleSearch}
        />
        <Box className="export-button-box">
          <Button
            fullWidth
            iconify="fa-solid:file-export"
            buttonColor="white"
            textColor="#454B5C"
            loading={isLoadingDownloadExcel}
            onClick={downloadExcel}
          >
            匯出表單
          </Button>
        </Box>
      </UiActionsBox>
      <UiTableBox>
        <CountyCmsStudentsTable
          unit={unit}
          students={students}
          isLoading={isLoading}
        />
      </UiTableBox>
      <UiTablePaginationBox>
        <TablePagination
          pagination={pagination}
          onChangeRowsPerPage={changeRowsPerPage}
          onChangePage={changePage}
        />
      </UiTablePaginationBox>
    </Box>
  );
};
