import { CountyCmsLayout, CountyCmsPage } from 'components';
import { COUNTY_CMS_PATH } from './constants';

const routes = [
  {
    path: COUNTY_CMS_PATH.COUNTY_CMS,
    component: CountyCmsLayout,
    routes: [
      {
        path: `${COUNTY_CMS_PATH.COUNTY_CMS}`,
        component: CountyCmsPage,
        exact: true
      },
      {
        path: `${COUNTY_CMS_PATH.COUNTY_CMS}${COUNTY_CMS_PATH.LEARNING_REPORT}`,
        component: CountyCmsPage,
        exact: true
      },
      {
        path: `${COUNTY_CMS_PATH.COUNTY_CMS}${COUNTY_CMS_PATH.LEARNING_REPORT}${COUNTY_CMS_PATH.ORGANIZATIONS}`,
        component: CountyCmsPage,
        exact: true,
      },
      {
        path: `${COUNTY_CMS_PATH.COUNTY_CMS}${COUNTY_CMS_PATH.LEARNING_REPORT}${COUNTY_CMS_PATH.SCHOOLS}`,
        component: CountyCmsPage,
        exact: true,
      },
      {
        path: `${COUNTY_CMS_PATH.COUNTY_CMS}${COUNTY_CMS_PATH.LEARNING_REPORT}${COUNTY_CMS_PATH.STUDENTS}`,
        component: CountyCmsPage,
        exact: true,
      },
    ]
  }
];

export default routes;
