import { ToggleButton } from '@material-ui/lab';
import React from 'react';
import PropTypes from 'prop-types';
import { UiToggleButtonGroup } from './CountyCmsToggleButton.style';

export const CountyCmsToggleButton = ({
  unit,
  buttons = [{ label: '學校', value: 'school' }],
  onChange
}) => {
  return (
    <UiToggleButtonGroup value={unit} exclusive onChange={onChange}>
      {buttons.map(button => {
        return (
          <ToggleButton key={button.value} value={button.value}>
            {button.label}
          </ToggleButton>
        );
      })}
    </UiToggleButtonGroup>
  );
};

CountyCmsToggleButton.propTypes = {
  unit: PropTypes.string,
  buttons: PropTypes.array,
  onChange: PropTypes.func,
};
