import { ROLES } from 'constants/index';

import organizationRoutes from 'routes/routesConfig/organizationRoutes';
import homeRoutes from 'routes/routesConfig/homeRoutes';
import commonRoutes from 'routes/routesConfig/commonRoutes';
import countyRoutes from 'routes/routesConfig/countyCmsRoutes';

export const getAuthRoutes = role => {
  let authRoutes = [];

  switch (role) {
    case ROLES.USER:
      authRoutes = authRoutes.concat([
        ...homeRoutes,
        ...organizationRoutes,
        ...countyRoutes
      ]);
      break;
    default:
      break;
  }

  return authRoutes.concat(commonRoutes);
};
