import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { UiTableContainer } from './CountyCmsStudentsTable.style';
import { TABLE_HEAD_CELLS } from './CountyCmsStudentsTable.constant';

/**
 * 學習報告 - 學校使用紀錄表格
 */
export const CountyCmsStudentsTable = ({
  unit,
  students,
  isLoading,
}) => {
  const tableHeadCells = TABLE_HEAD_CELLS[unit];
  return (
    <UiTableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeadCells.map(cell => {
              return (
                <TableCell key={cell.name} align="left" scope="column">
                  {cell.name}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
        {isLoading ? (
            <TableRow>
              <TableCell
                colSpan={tableHeadCells.length}
                style={{ textAlign: 'center' }}
              >
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
          students.map(school => {
            return (
              <TableRow key={uuidV4()}>
                {Object.entries(school).map(([key, value]) => (
                  <TableCell key={uuidV4()}>
                    <Box>{value || '-'}</Box>
                  </TableCell>
                ))}
              </TableRow>
            );
          })
        )}
        </TableBody>
        </Table>
        </UiTableContainer>
  );
};

CountyCmsStudentsTable.propTypes = {
  unit: PropTypes.string,
  students: PropTypes.array,
  isLoading: PropTypes.bool,
};