import React from 'react';
import RenderRoutes from 'routes/RenderRoutes';
import PropTypes from 'prop-types';
import Header from './Header';
import { UiCountyCmsLayout, UiCountyCmsChildrenBox } from './CountyCmsLayout.style';

export const CountyCmsLayout = ({ routes }) => {
  return (
    <UiCountyCmsLayout>
      <Header />
      <UiCountyCmsChildrenBox>
        <RenderRoutes routes={routes} />
      </UiCountyCmsChildrenBox>
    </UiCountyCmsLayout>
  );
};

CountyCmsLayout.propTypes = {
  routes: PropTypes.node
};
