import { UNIT } from 'components/pages/CountyCmsPage/LearningReport/Students/Students.constant';

export const TABLE_HEAD_CELLS = {
  [UNIT.TEST]: [
    {
      name: '縣市'
    },
    {
      name: '鄉鎮市區'
    },
    {
      name: '學校名稱'
    },
    {
      name: '班級'
    },
    {
      name: '年級'
    },
    {
      name: '座號'
    },
    {
      name: '學生帳號'
    },
    {
      name: '學生姓名'
    },
    {
      name: '派卷老師'
    },
    {
      name: '測驗名稱'
    },
    {
      name: '測驗時長'
    },
    {
      name: '交卷時間'
    },
    {
      name: '作答時長'
    },
    {
      name: '測驗得分'
    },
    {
      name: ''
    },
  ],
  [UNIT.VIDEO]: [
    {
      name: '縣市'
    },
    {
      name: '鄉鎮市區'
    },
    {
      name: '學校名稱'
    },
    {
      name: '班級'
    },
    {
      name: '年級'
    },
    {
      name: '座號'
    },
    {
      name: '學生帳號'
    },
    {
      name: '學生姓名'
    },
    {
      name: '指派老師'
    },
    {
      name: '影音科目'
    },
    {
      name: '影片名稱'
    },
    {
      name: '學年度'
    },
    {
      name: '學制'
    },
    {
      name: '冊次'
    },
    {
      name: '版本'
    },
    {
      name: '老師指派時間'
    },
    {
      name: '學生觀看時間'
    }
  ]
};