import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { COUNTY_CMS_PATH } from 'routes/routesConfig/constants';
import { AppBar, Box, Tab, Typography } from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import { UiCountyCmsPage, UiMainBox, UiTabList, UiTabPanel } from './CountyCmsPage.style';
import Organizations from './LearningReport/Organizations';
import Schools from './LearningReport/Schools';
import Students from './LearningReport/Students';

const TAB_VALUE = {
  ORGANIZATIONS: 'organizations',
  SCHOOLS: 'schoolUsageRecords',
  STUDENTS: 'studentPersonalRecords'
};
const TAB_PATH_MAP = {
  [COUNTY_CMS_PATH.ORGANIZATIONS]: TAB_VALUE.ORGANIZATIONS,
  [COUNTY_CMS_PATH.SCHOOLS]: TAB_VALUE.SCHOOLS,
  [COUNTY_CMS_PATH.STUDENTS]: TAB_VALUE.STUDENTS
};

/**
 * 學習報告頁面
 */
export const CountyCmsPage = () => {
  const location = useLocation();
  const pathLeaf = `/${location.pathname.split('/').slice(-1)[0]}`;
  const tabValue = TAB_PATH_MAP[pathLeaf];

  return (
    <UiCountyCmsPage>
      <UiMainBox>
        <TabContext value={tabValue}>
          <AppBar position="static" className="app-bar">
            <Box className="title-tabs-box">
              <Box className="title-box">
                <Typography variant="h5" className="title">
                  學習報告
                </Typography>
              </Box>
              <UiTabList className="tabs-box" variant="scrollable" scrollButtons="on">
                <Tab
                  label="機構總覽"
                  component={Link}
                  to={`${COUNTY_CMS_PATH.COUNTY_CMS}${COUNTY_CMS_PATH.LEARNING_REPORT}${COUNTY_CMS_PATH.ORGANIZATIONS}`}
                  value={TAB_VALUE.ORGANIZATIONS}
                />
                <Tab
                  label="學校使用紀錄"
                  component={Link}
                  to={`${COUNTY_CMS_PATH.COUNTY_CMS}${COUNTY_CMS_PATH.LEARNING_REPORT}${COUNTY_CMS_PATH.SCHOOLS}`}
                  value={TAB_VALUE.SCHOOLS}
                />
                <Tab
                  label="學生個人紀錄"
                  component={Link}
                  to={`${COUNTY_CMS_PATH.COUNTY_CMS}${COUNTY_CMS_PATH.LEARNING_REPORT}${COUNTY_CMS_PATH.STUDENTS}`}
                  value={TAB_VALUE.STUDENTS}
                />
              </UiTabList>
            </Box>
            {/* 20231016 確認後，因 OneStudy 那沒有所以先不用 */}
            {/* <Box className="export-button-box">
              <Button
                iconify="fa-solid:file-export"
                buttonColor="white"
                textColor="#454B5C"
                fullWidth
              >
                匯出所有項目表單
              </Button>
            </Box> */}
          </AppBar>
          <UiTabPanel value={TAB_VALUE.ORGANIZATIONS}>
            <Organizations />
          </UiTabPanel>
          <UiTabPanel value={TAB_VALUE.SCHOOLS}>
            <Schools />
          </UiTabPanel>
          <UiTabPanel value={TAB_VALUE.STUDENTS}>
            <Students />
          </UiTabPanel>
        </TabContext>
      </UiMainBox>
    </UiCountyCmsPage>
  );
};
