import { COUNTY_CMS_PATH } from 'routes/routesConfig/constants';

export const UNIT = {
  TEST: 'test',
  VIDEO: 'video'
};

export const LEARNING_REPORT_SCHOOLS_URL = `${COUNTY_CMS_PATH.COUNTY_CMS}${COUNTY_CMS_PATH.LEARNING_REPORT}${COUNTY_CMS_PATH.STUDENTS}`;

export const TOGGLE_BUTTONS = [
  { label: '測驗完成紀錄', value: UNIT.TEST },
  { label: '影音觀看紀錄', value: UNIT.VIDEO }
];

const SEARCH_CONDITION_OPTION_VALUE = {
  COUNTY: 'county',
  CITY: 'city',
  SCHOOL_NAME: 'schoolName',
  CLASSROOM_NAME: 'classroomName',
  STUDENT_ACCOUNT: 'studentAccount',
  STUDENT_NAME: 'studentName',
  EXAM_NAME: 'examName',
  VIDEO_NAME: 'videoName',
};

export const SEARCH_CONDITION_API_KEY_MAP = {
  [SEARCH_CONDITION_OPTION_VALUE.COUNTY]: 'cityName',
  [SEARCH_CONDITION_OPTION_VALUE.CITY]: 'districtName',
  [SEARCH_CONDITION_OPTION_VALUE.SCHOOL_NAME]: 'officialName',
  [SEARCH_CONDITION_OPTION_VALUE.CLASSROOM_NAME]: 'className',
  [SEARCH_CONDITION_OPTION_VALUE.STUDENT_ACCOUNT]: 'studentId',
  [SEARCH_CONDITION_OPTION_VALUE.STUDENT_NAME]: 'studentName',
  [SEARCH_CONDITION_OPTION_VALUE.EXAM_NAME]: 'examName',
  [SEARCH_CONDITION_OPTION_VALUE.VIDEO_NAME]: 'videoName',
};

export const SEARCH_CONDITION_OPTION = {
  [UNIT.TEST]: [
    {
      label: '縣市',
      value: SEARCH_CONDITION_OPTION_VALUE.COUNTY,
    },
    {
      label: '鄉鎮市區',
      value: SEARCH_CONDITION_OPTION_VALUE.CITY,
    },
    {
      label: '學校名稱',
      value: SEARCH_CONDITION_OPTION_VALUE.SCHOOL_NAME,
    },
    {
      label: '班級名稱',
      value: SEARCH_CONDITION_OPTION_VALUE.CLASSROOM_NAME,
    },
    {
      label: '學生帳號',
      value: SEARCH_CONDITION_OPTION_VALUE.STUDENT_ACCOUNT,
    },
    {
      label: '學生名字',
      value: SEARCH_CONDITION_OPTION_VALUE.STUDENT_NAME,
    },
    {
      label: '測驗名稱',
      value: SEARCH_CONDITION_OPTION_VALUE.EXAM_NAME,
    },
  ],
  [UNIT.VIDEO]: [
    {
      label: '縣市',
      value: SEARCH_CONDITION_OPTION_VALUE.COUNTY,
    },
    {
      label: '鄉鎮市區',
      value: SEARCH_CONDITION_OPTION_VALUE.CITY,
    },
    {
      label: '學校名稱',
      value: SEARCH_CONDITION_OPTION_VALUE.SCHOOL_NAME,
    },
    {
      label: '班級名稱',
      value: SEARCH_CONDITION_OPTION_VALUE.CLASSROOM_NAME,
    },
    {
      label: '學生帳號',
      value: SEARCH_CONDITION_OPTION_VALUE.STUDENT_ACCOUNT,
    },
    {
      label: '學生名字',
      value: SEARCH_CONDITION_OPTION_VALUE.STUDENT_NAME,
    },
    {
      label: '影片名稱',
      value: SEARCH_CONDITION_OPTION_VALUE.VIDEO_NAME,
    },
  ]
};


// fake students
// const students = [
//   {
//     county: '新北市',
//     city: '永和區',
//     schoolName: '秀山國小',
//     classroomName: '613',
//     grades: '一、二、三、四、五',
//     seatNumber: '12',
//     account: 'aaaaadddddd',
//     name: '王小明',
//     assigningTeacher: '王大陸',
//     submissionTime: '2023/02/07 18:23',
//     answerDuration: '120分鐘',
//     testName: '雅量雅量雅量雅量',
//     testScore: '89分',
//     testResult: (
//       <IconButton style={{ background: '#31819314', color: '#318193' }}>
//         <VisibilityIcon />
//       </IconButton>
//     )
//   }
// ];