import { Api } from 'services/api';

/**
 * 取得機構列表
 */
export const getOrganizations = async params => {
  const { organGroups } = params;
  const response = await Api.get(`/organGroups/${organGroups}/organizations`);
  return response;
};

/**
 * 取得機構總覽（卡片、列表）
 * @param {object} params { organGroupId: string; organizationId: string; skip: string; limit: string }
 */
export const getSummaryBoard = async params => {
  const { organGroupId, ...payload } = params;
  const response = await Api.get(`/organGroups/${organGroupId}/summaryboard`, {
    params: payload
  });
  return response;
};

/**
 * 取得學校使用紀錄 - 以學校為單位
 * @param {object} params { organGroupId: string; cityName: string; districtName: string; officialName: string; startAt: string; endAt: string; skip: string; limit: string }
 * @returns
 */
export const getSchoolReport = async ({ params, signal }) => {
  const { organGroupId, ...payload } = params;
  const response = await Api.get(`/organGroups/${organGroupId}/schoolReport`, {
    params: payload,
    signal,
  });
  return response;
};

/**
 * 取得學校使用紀錄 - 以班級為單位
 * @param {object} params { organGroupId: string; cityName: string; districtName: string; officialName: string; startAt: string; endAt: string; skip: string; limit: string }
 * @returns
 */
export const getClassReport = async ({ params, signal }) => {
  const { organGroupId, ...payload } = params;
  const response = await Api.get(`/organGroups/${organGroupId}/classReport`, {
    params: payload,
    signal,
  });
  return response;
};

/**
 * 取得學生個人紀錄 - 以測驗完成紀錄為單位
 * @param {object} params {
 *    organGroupId: string;
 *    organizationId: string;
 *    classId: string;
 *    teacherId: string;
 *    studentId: string;
 *    studentName: string;
 *    cityName: string;
 *    districtName: string;
 *    organizationName: string;
 *    className: string;
 *    examName: string;
 *    startAt: string;
 *    endAt: string;
 *    skip: string;
 *    limit: string
 *  }
 * @returns
 */
export const getExamRecords = async ({ params, signal }) => {
  const { organGroupId, ...payload } = params;
  const response = await Api.get(`/organGroups/${organGroupId}/examRecords`, {
    params: payload,
    signal,
  });
  return response;
};

/**
 * 取得學生個人紀錄 - 以影音觀看紀錄為單位
 * @param {object} params {
 *    organGroupId: string;
 *    organizationId: string;
 *    classId: string;
 *    teacherId: string;
 *    studentId: string;
 *    videoName: string;
 *    startAt: string;
 *    endAt: string;
 *    skip: string;
 *    limit: string
 *  }
 * @returns
 */
export const getViewRecords = async ({ params, signal }) => {
  const { organGroupId, ...payload } = params;
  const response = await Api.get(`/organGroups/${organGroupId}/viewRecords`, {
    params: payload,
    signal,
  });
  return response;
};

/**
 * 取得學習報告 excel 資料
 * @param {object} params {
 *   organGroupId: string;
 *   recordType: RECORD_TYPE[],
 *   organizationId: string;
 *   teacherId: string;
 *   classId: string;
 *   studentId: string;
 *   skip: string;
 *   limit: string }
 */
export const getCountyExcel = async params => {
  const { organGroupId, recordType, ...payload } = params;
  const response = await Api.get(
    `/organGroups/${organGroupId}/excel/${recordType}`,
    {
      params: payload
    }
  );
  return response;
};

// /**
//  * 創建自訂相簿
//  * Create Custom Group Album
//  */

// export const createCustomGroupAlbum = classId => async params => {
//   const response = await Api.post(`/groups/${classId}/albums`, params);
//   return response;
// };

// /**
//  * 創建自訂相簿
//  * Update Custom Group Album
//  */

// export const updateCustomGroupAlbum = (classId, albumId) => async params => {
//   const response = await Api.put(`/groups/${classId}/albums/${albumId}`, params);
//   return response;
// };
// /**
//  * 刪除自訂相簿
//  * Delete Custom Group Album
//  */
// export const deleteCustomGroupAlbum = classId => async albumId => {
//   const response = await Api.delete(`/groups/${classId}/albums/${albumId}`);
//   return response;
// };
