import styled from 'styled-components';
import { ToggleButtonGroup } from '@material-ui/lab';

export const UiToggleButtonGroup = styled(ToggleButtonGroup)(() => {
  return {
    '&.MuiToggleButtonGroup-root': {
      borderRadius: '8px',
      border: '1px solid rgba(145, 158, 171, 0.12)',
      padding: '4px',
      gap: '4px',

      '> .MuiToggleButton-root': {
        border: 'unset',
        fontSize: '14px',
        fontWeight: '600',
        '&:hover': {
          borderRadius: '8px',
        },

        '&.Mui-selected': {
          background: 'rgba(49, 129, 147, 0.08)',
          borderRadius: '8px',
          color: '#318193',
        }
      },
    },
  };
});