import { Icon as Iconify } from '@iconify/react';
import {
  Box,
  InputAdornment,
  MenuItem,
  TextField
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { UiKeywordFilter } from './KeywordFilter.style';

export const KeywordFilter = ({
  searchConditionOptions,
  searchCondition,
  keyword,
  onChangeSelect,
  onChangeKeyword,
}) => {
  return (
    <UiKeywordFilter>
      <Box className="select-box">
        <TextField
          label="搜尋條件"
          select
          fullWidth
          size="small"
          variant="outlined"
          value={searchCondition}
          onChange={onChangeSelect}
        >
          {searchConditionOptions.map(condition => (
            <MenuItem key={condition.value} value={condition.value}>
              {condition.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box className="keyword-box">
        <TextField
          placeholder="搜尋"
          fullWidth
          size="small"
          variant="outlined"
          value={keyword}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="ion:search" width="24" color="#919EAB" />
              </InputAdornment>
            )
          }}
          onChange={onChangeKeyword}
        />
      </Box>
    </UiKeywordFilter>
  );
};

KeywordFilter.propTypes = {
  searchConditionOptions: PropTypes.array,
  searchCondition: PropTypes.string,
  keyword: PropTypes.string,
  onChangeSelect: PropTypes.func,
  onChangeKeyword: PropTypes.func,
};