import { TablePagination as MuiTablePagination } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export const TablePagination = ({
  rowsPerPageOptions=[10, 25, 100],
  pagination={
    page: 0,
    rowsPerPage: rowsPerPageOptions[0],
    totalCount: 0,
  },
  onChangePage,
  onChangeRowsPerPage
}) => {
  return (
    <MuiTablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={pagination.totalCount}
      labelRowsPerPage="每頁顯示"
      rowsPerPage={pagination?.rowsPerPage || rowsPerPageOptions[0]}
      page={pagination.page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  );
};

TablePagination.propTypes = {
  rowsPerPageOptions: PropTypes.array,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    rowsPerPage: PropTypes.number || undefined,
    totalCount: PropTypes.number,
  }),
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func
};
