import { UNIT } from 'components/pages/CountyCmsPage/LearningReport/Schools/Schools.constant';

export const TABLE_HEAD_CELLS = {
  [UNIT.SCHOOL]: [
    {
      name: '縣市'
    },
    {
      name: '鄉鎮市區'
    },
    {
      name: '學校名稱'
    },
    {
      name: '機構人數'
    },
    {
      name: '實際使用人數'
    },
    {
      name: '老師影音指派次數'
    },
    {
      name: '學生影音觀看次數'
    },
    {
      name: '測驗次數'
    },
    {
      name: '測驗平均分'
    }
  ],
  [UNIT.CLASSROOM]: [
    {
      name: '縣市'
    },
    {
      name: '鄉鎮市區'
    },
    {
      name: '學校名稱'
    },
    {
      name: '班級名稱'
    },
    {
      name: '年級'
    },
    {
      name: '導師姓名'
    },
    {
      name: '班級人數'
    },
    {
      name: '實際使用人數'
    },
    {
      name: '老師影音指派次數'
    },
    {
      name: '學生影音觀看次數'
    },
    {
      name: '測驗次數'
    },
    {
      name: '測驗平均分'
    }
  ]
};