import { UNIT } from 'components/pages/CountyCmsPage/LearningReport/Schools/Schools.constant';
import styled from 'styled-components';
import { TableContainer } from '@material-ui/core';

const stickyStyle = {
  position: 'sticky',
  left: 0,
};

export const UiTableContainer = styled(TableContainer)(({ theme, unit }) => ({
  boxShadow: 'unset',
  '& > table': {
    boxSizing: 'content-box',
    minWidth: unit === UNIT.SCHOOL ? '900px' : '1400px',
    tableLayout: 'fixed',
    width: 'fill-available',

    '& > thead': {
      backgroundColor: '#F4F6F8',
      '& > tr > th': {
        borderBottom: 'unset',
        color: '#637381',
        fontSize: '16px',
        fontWeight: 500,
        minWidth: '65px',
        maxWidth: '100px',
        wordWrap: 'break-word',
        '&:first-child': {
          borderRadius: '8px 0 0 8px',
          backgroundColor: '#F4F6F8',
          ...stickyStyle,
        },
        '&:last-child': {
          borderRadius: '0 8px 8px 0',
        },
      }
    },
    '& > tbody': {
      '& > tr > td': {
        borderBottom: 'unset',
        verticalAlign: 'top',
        minWidth: '65px',
        maxWidth: '100px',
        wordWrap: 'break-word',
        '> p': {
          color: '#212B36',
          fontSize: '16px',
          fontWeight: 500,
        },
        '&:first-child': {
          backgroundColor: '#FFF',
          ...stickyStyle,
        },
      },
      // '& > tr:nth-of-type(even)': {
      //   backgroundColor: theme.palette.action.hover,
      // }
    },
  }
}));