export const DEVICE = {
  W_MAX_MOBILE: 746,
  W_MAX_TABLET: 970,
};

export const ORGAN_GROUP_ID = {
  KAOHSIUNG_CITY: 'kaohsiung_city',
};

export const RECORD_TYPE = {
  SUMMARY_BOARD: 'summaryBoard',
  SCHOOL_REPORT: 'schoolReport',
  CLASS_REPORT: 'classReport',
  VIEW_RECORD: 'viewRecord',
  EXAM_RECORD: 'examRecord',
};