import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';

export const UiHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '#FFF',
  width: '100%',
  '&.MuiBox-root': {
    padding: '0px 40px',
  }
}));

export const UiWelcomeMsg = styled(Typography)(() => ({
  fontWeight: 700,
}));