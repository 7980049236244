import styled from 'styled-components';
import { Avatar } from '@material-ui/core';

export const UiAvatarMenu = styled.div`
	display: block;
`;

export const UiButtonWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const UiAvatar = styled(Avatar)`
	background-color: #B6D8DF;
`;
