import { useSelector, useDispatch } from 'react-redux';
import {
  getOrganizations as getOrganizationsApi,
  getSummaryBoard as getSummaryBoardApi,
  getSchoolReport as getSchoolReportApi,
  getClassReport as getClassReportApi,
  getExamRecords as getExamRecordsApi,
  getViewRecords as getViewRecordsApi,
} from 'services/api/organGroups';

const GET_ORGANIZATIONS_FROM_API = 'GET_ORGANIZATIONS_FROM_API';
const GET_SUMMARY_BOARD_FROM_API = 'GET_SUMMARY_BOARD_FROM_API';
const GET_SCHOOL_REPORT_FROM_API = 'GET_SCHOOL_REPORT_FROM_API';
const GET_CLASS_REPORT_FROM_API = 'GET_CLASS_REPORT_FROM_API';
const GET_EXAM_RECORDS_FROM_API = 'GET_EXAM_RECORDS_FROM_API';
const GET_VIEW_RECORDS_FROM_API = 'GET_VIEW_RECORDS_FROM_API';
const initState = {
  organizationsApiData: {
    data: [],
    total: 0
  },
  summaryBoardApiData: {
    createdAt: 0,
    customer: {
      latestCreatedAt: 0,
      total: 0
    },
    group: {
      latestCreatedAt: 0,
      total: 0
    },
    organization: {
      latestCreatedAt: 0,
      total: 0
    },
    staff: {
      latestCreatedAt: 0,
      total: 0
    },
    organizations: []
  },
  schoolReportApiData: {
    records: [],
    total: 0
  },
  classReportApiData: {
    records: [],
    total: 0
  },
  examRecordsApiData: {
    records: [],
    total: 0
  },
  viewRecordsApiData: {
    records: [],
    total: 0
  },
};

const actions = {
  getOrganizationsFromApi: payload => ({
    type: GET_ORGANIZATIONS_FROM_API,
    payload
  }),
  getSummaryBoardFromApi: payload => ({
    type: GET_SUMMARY_BOARD_FROM_API,
    payload
  }),
  getSchoolReportFromApi: payload => ({
    type: GET_SCHOOL_REPORT_FROM_API,
    payload
  }),
  getClassReportFromApi: payload => ({
    type: GET_CLASS_REPORT_FROM_API,
    payload
  }),
  getExamRecordsFromApi: payload => ({
    type: GET_EXAM_RECORDS_FROM_API,
    payload
  }),
  getViewRecordsFromApi: payload => ({
    type: GET_VIEW_RECORDS_FROM_API,
    payload
  }),
};

export const useCountyCms = () => {
  const dispatch = useDispatch();
  const {
    organizationsApiData,
    examRecordsApiData,
    summaryBoardApiData,
    schoolReportApiData,
    classReportApiData,
    viewRecordsApiData
  } = useSelector(state => state.countyCms);

  /* 取得機構列表 */
  const getOrganizationsFromApi = async () => {
    const response = await getOrganizationsApi();
    if (!response) return { status: 'aborted' };
    dispatch(actions.getOrganizationsFromApi(response.data));
  };

  /* 取得機構總覽 */
  const getSummaryBoardFromApi = async params => {
    const response = await getSummaryBoardApi(params);
    if (!response) return { status: 'aborted' };
    dispatch(actions.getSummaryBoardFromApi(response.data));
    return response;
  };

  /* 取得學校使用紀錄 - 學校 */
  const getSchoolReportFromApi = async ({ params, signal }) => {
    const response = await getSchoolReportApi({ params, signal });
    if (!response) return { status: 'aborted' };
    dispatch(actions.getSchoolReportFromApi(response.data));
    return response;
  };

  /* 取得學校使用紀錄 - 班級 */
  const getClassReportFromApi = async ({ params, signal }) => {
    const response = await getClassReportApi({ params, signal });
    if (!response) return { status: 'aborted' };
    dispatch(actions.getClassReportFromApi(response.data));
    return response;
  };

  /* 取得學生個人紀錄 - 測驗完成紀錄 */
  const getExamRecordsFromApi = async ({ params, signal }) => {
    const response = await getExamRecordsApi({ params, signal });
    if (!response) return { status: 'aborted' };
    dispatch(actions.getExamRecordsFromApi(response.data));
    return response;
  };

  /* 取得學生個人紀錄 - 影音觀看紀錄 */
  const getViewRecordsFromApi = async ({ params, signal }) => {
    const response = await getViewRecordsApi({ params, signal });
    if (!response) return { status: 'aborted' };
    dispatch(actions.getViewRecordsFromApi(response.data));
    return response;
  };

  return [
    {
      organizationsApiData,
      examRecordsApiData,
      summaryBoardApiData,
      schoolReportApiData,
      classReportApiData,
      viewRecordsApiData,
    },
    {
      getOrganizationsFromApi,
      getSummaryBoardFromApi,
      getSchoolReportFromApi,
      getClassReportFromApi,
      getExamRecordsFromApi,
      getViewRecordsFromApi,
    }
  ];
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ORGANIZATIONS_FROM_API: {
      return {
        ...initState,
        organizationsApiData: action.payload
      };
    }
    case GET_SUMMARY_BOARD_FROM_API: {
      return {
        ...initState,
        summaryBoardApiData: action.payload
      };
    }
    case GET_SCHOOL_REPORT_FROM_API: {
      return {
        ...initState,
        schoolReportApiData: action.payload
      };
    }
    case GET_CLASS_REPORT_FROM_API: {
      return {
        ...initState,
        classReportApiData: action.payload
      };
    }
    case GET_EXAM_RECORDS_FROM_API: {
      return {
        ...initState,
        examRecordsApiData: action.payload
      };
    }
    case GET_VIEW_RECORDS_FROM_API: {
      return {
        ...initState,
        viewRecordsApiData: action.payload
      };
    }
    default:
      return state;
  }
};

export default reducer;
