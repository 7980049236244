import { Box, CircularProgress } from '@material-ui/core';
import {
  Button,
  CountyCmsTotalCard,
  CountyCmsFilters,
  CountyCmsOrganizationsTable,
  TablePagination
} from 'components';
import { ORGAN_GROUP_ID, RECORD_TYPE } from 'constants/countyCms';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { getCountyExcel } from 'services/api/organGroups';
import { useCountyCms } from 'store/countyCms';
import { useAlert } from 'utils/hooks/useAlert';
import { FILE_EXTENSION_MAP, downloadFile } from 'utils/downloadFile';
import { v4 as uuidV4 } from 'uuid';
import {
  UiTotalCardsBox,
  UiActionsBox,
  UiTableBox,
  UiTablePaginationBox
} from './Organizations.style';
import { SEARCH_CONDITION_API_KEY_MAP, SEARCH_CONDITION_OPTIONS } from './Organizations.constant';

const TOTAL_CARDS = [
  {
    id: uuidV4(),
    key: 'organization',
    iconSvgName: 'NoteAlt',
    title: '機構總數',
    total: 0,
    latestCreatedAt: Date.now(),
  },
  {
    id: uuidV4(),
    key: 'group',
    iconSvgName: 'UniversityGraduate',
    title: '班級總數',
    total: 0,
    latestCreatedAt: Date.now(),
  },
  {
    id: uuidV4(),
    key: 'staff',
    iconSvgName: 'RecordVoiceOver',
    title: '教師人數',
    total: 0,
    latestCreatedAt: Date.now(),
  },
  {
    id: uuidV4(),
    key: 'customer',
    iconSvgName: 'Users',
    title: '學生人數',
    total: 0,
    latestCreatedAt: Date.now(),
  }
];

/**
 * 學習報告 - 機構總覽頁面
 */
export const Organizations = () => {
  const { setAlert } = useAlert();
  const [{ summaryBoardApiData }, { getSummaryBoardFromApi }] = useCountyCms();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDownloadExcel, setIsLoadingDownloadExcel] = useState(false);
  const [filter, setFilter] = useState({
    searchCondition: SEARCH_CONDITION_OPTIONS[0].value,
    keyword: ''
  });
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    totalCount: 0,
  });

  const totalCards = useMemo(() => {
    const cards = TOTAL_CARDS.map(card => {
      let bottomText;
      const key = card.key;
      const latestTime = summaryBoardApiData[key].latestCreatedAt ? format(summaryBoardApiData[key].latestCreatedAt, 'yyyy-MM-dd') : '-';
      switch (key) {
        case 'organization':
          bottomText = `最新建立時間 ${latestTime}`;
          break;
        case 'group':
          bottomText = `最新創立時間 ${latestTime}`;
          break;
        case 'staff':
        case 'customer':
        default:
          bottomText = `最新加入時間 ${latestTime}`;
          break;
      }
      return {
        ...card,
        ...summaryBoardApiData[key],
        bottomText,
      };
    });
    return cards;
  }, [summaryBoardApiData]);

  /* 取得機構總覽 */
  const fetchSummaryBoard = async (params) => {
    const skip = pagination.page * pagination.rowsPerPage;
    const limit = pagination.rowsPerPage;
    const newParams = {
      organGroupId: ORGAN_GROUP_ID.KAOHSIUNG_CITY,
      skip,
      limit,
      ...params,
    };
    const { status, data } = await getSummaryBoardFromApi(newParams);
    if (status === 'success') {
      setPagination(prev => ({
        ...prev,
        totalCount: data.organization.total,
      }));
    } else {
      throw new Error('取得機構總覽失敗');
    }
  };

  /* 改每頁列數 */
  const changeRowsPerPage = async event => {
    try {
      setIsLoading(true);
      const rowsPerPage = parseInt(event.target.value, 10);
      setPagination(prev => ({
        ...prev,
        page: 0,
        rowsPerPage
      }));
      const params = {
        skip: 0,
        limit: rowsPerPage,
      };
      await fetchSummaryBoard(params);
    } catch (error) {
      const errMsg = error?.message || '程式錯誤';
      setAlert(errMsg, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  /* 切上下頁 */
  const changePage = async (event, newPage) => {
    try {
      setIsLoading(true);
      setPagination(prev => ({
        ...prev,
        page: newPage
      }));
      const params = {
        skip: newPage * pagination.rowsPerPage,
        limit: pagination.rowsPerPage,
      };
      await fetchSummaryBoard(params);
    } catch (error) {
      const errMsg = error?.message || '程式錯誤';
      setAlert(errMsg, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  /* change 搜尋條件、關鍵字 */
  const handleChangeSelect = event => {
    const searchCondition = event.target.value;
    setFilter(prev => ({
      ...prev,
      searchCondition
    }));
  };

  /* change 搜尋條件、關鍵字 */
  const handleChangeKeyword = event => {
    const keyword = event.target.value;
    setFilter(prev => ({
      ...prev,
      keyword
    }));
  };

  /* 搜尋 */
  const handleSearch = async searchParam => {
    try {
      setIsLoading(true);
      setPagination(prev => ({
        ...prev,
        page: 0
      }));
      setFilter(searchParam);
      const keywordFilter = {
        [SEARCH_CONDITION_API_KEY_MAP[filter.searchCondition]]: filter.keyword
      };
      const params = {
        skip: 0,
        limit: pagination.rowsPerPage,
        ...(searchParam.keyword && keywordFilter)
      };
      await fetchSummaryBoard(params);
    } catch (error) {
      const errMsg = error?.message || '程式錯誤';
      setAlert(errMsg, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  /* 取得 excel 資料 */
  const fetchExcelData = async () => {
    const keywordFilter = {
      [SEARCH_CONDITION_API_KEY_MAP[filter.searchCondition]]: filter.keyword
    };
    const skip = pagination.page * pagination.rowsPerPage;
    const params = {
      organGroupId: ORGAN_GROUP_ID.KAOHSIUNG_CITY,
      recordType: RECORD_TYPE.SUMMARY_BOARD,
      skip,
      ...(filter.keyword && keywordFilter)
    };
    const { isSuccess, data } = await getCountyExcel(params);
    if (isSuccess) {
      return data;
    } else {
      throw new Error('取得 excel 資料失敗');
    }
  };

  /* 匯出表單 */
  const downloadExcel = async () => {
    try {
      setIsLoadingDownloadExcel(true);
      const { excelData, fileName } = await fetchExcelData();
      const buffer = excelData[1].data;
      const newFilename = `${fileName}.${FILE_EXTENSION_MAP.XLSX}`;
      await downloadFile({ buffer, fileName: newFilename, type: FILE_EXTENSION_MAP.XLSX });
    } catch (error) {
      const errMsg = error?.message || '匯出表單失敗';
      setAlert(errMsg, 'error');
    } finally {
      setIsLoadingDownloadExcel(false);
    }
  };

  const keywordFilterProp = {
    searchConditionOptions: SEARCH_CONDITION_OPTIONS,
    searchCondition: filter.searchCondition,
    keyword: filter.keyword,
    onChangeSelect: handleChangeSelect,
    onChangeKeyword: handleChangeKeyword
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      await fetchSummaryBoard();
    } catch (error) {
      const errMsg = error?.message || '程式錯誤';
      setAlert(errMsg, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? (
    <Box style={{ textAlign: 'center', padding: '20px' }}>
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <UiTotalCardsBox>
        {totalCards.map(totalCard => {
          return (
            <Box key={totalCard.id}>
              <CountyCmsTotalCard
                iconSvgName={totalCard.iconSvgName}
                title={totalCard.title}
                total={totalCard.total}
                bottomText={totalCard.bottomText}
              />
            </Box>
          );
        })}
      </UiTotalCardsBox>
      <UiActionsBox>
        <CountyCmsFilters
          methods={['keyword']}
          keywordFilterProp={keywordFilterProp}
          onSearch={handleSearch}
        />
        <Box className="export-button-box">
          <Button
            fullWidth
            iconify="fa-solid:file-export"
            buttonColor="white"
            textColor="#454B5C"
            loading={isLoadingDownloadExcel}
            onClick={downloadExcel}
          >
            匯出表單
          </Button>
        </Box>
      </UiActionsBox>
      <UiTableBox>
        <CountyCmsOrganizationsTable />
      </UiTableBox>
      <UiTablePaginationBox>
        <TablePagination
          pagination={pagination}
          onChangeRowsPerPage={changeRowsPerPage}
          onChangePage={changePage}
        />
      </UiTablePaginationBox>
    </Box>
  );
};
