import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const UiCountyCmsTotalCard = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '256px',
  border: '1px solid rgba(145, 158, 171, 0.16)',
  borderRadius: '12px',
  boxShadow: '0px 8px 16px 0px rgba(145, 158, 171, 0.16)',
  padding: '18px 16px',
  position: 'relative',

  '.icon-box': {
    position: 'absolute',
    top: -20,
    background: '#318193',
    borderRadius: '12px',
    minHeight: '86px',
    maxWidth: '86px',
    width: '100%',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '.label-total-box': {
    textAlign: 'right',
    '.label': {
      color: '#637381',
      fontSize: '16px',
      fontWeight: 500,
    },
    '.total': {
      color: '#000',
      fontSize: '24px',
      fontWeight: 700,
    },
  },

  '.divider-box': {
    marginTop: '20px',
  },

  '.last-time-box': {
    marginTop: '16px',
    textAlign: 'right',
    '.last-time': {
      color: '#637381',
      fontSize: '14px',
      fontWeight: 400,
    },
  },
}));