import styled from 'styled-components';
import { Box, useMediaQuery } from '@material-ui/core';
import { DEVICE } from 'constants/countyCms';

export const UiToggleButtonBox = styled(Box)(() => {
  return {
    padding: '20px 24px 20px 24px',
  };
});

export const UiActionsBox = styled(Box)(() => {
  const isTablet = useMediaQuery(`(max-width:${DEVICE.W_MAX_TABLET}px)`);
  return {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: isTablet ? 'wrap' : 'no-wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '20px',
    padding: '20px 24px 24px 24px',

    '.export-button-box': {
      width: isTablet ? '100%' : 'unset',
      whiteSpace: 'nowrap',
    }
  };
});

export const UiTableBox = styled(Box)(() => ({
  padding: '0 8px',
}));

export const UiTablePaginationBox = styled(Box)(() => ({
  padding: '10px 8px',
}));