import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton as MatIconButton,
  Avatar as MatAvatar
} from '@material-ui/core';

import { useUser } from 'store/user';
import { ClickablePopoverMenu } from 'components';
import { COMPANY_TITLE } from 'constants/index';
import { useHistory } from 'react-router-dom';
import { COUNTY_CMS_PATH } from 'routes/routesConfig/constants';
import { UiAvatarMenu, UiNickname, UiButtonWrapper } from './AvatarMenu.style';


/**
 * Header 用頭像組件
 */

const isCompany = process.env.REACT_APP_TITLE === COMPANY_TITLE;
const COUNTY_CMS_URL = `${COUNTY_CMS_PATH.COUNTY_CMS}${COUNTY_CMS_PATH.LEARNING_REPORT}${COUNTY_CMS_PATH.ORGANIZATIONS}`;

export const AvatarMenu = () => {
  const history = useHistory();
  const [{ profile }, { userLogout }] = useUser();
  const logoutHandler = () => {
    userLogout();
  };

  const goToHome = () => {
    // eslint-disable-next-line max-len
    isCompany
      ? window.location.href = `https://course${process.env.REACT_APP_ENV === 'release' ? '' : '-' + process.env.REACT_APP_ENV}.oneclass.com.tw/home`
      : window.location.href = process.env.REACT_APP_URL_FORESTAGE;
  };

  const popoverMenuList = () => {
    const hasCountyCmsPermission = profile?.organizationGroups?.length > 0;
    const companyList = [
      { label: '回到班級', func: goToHome, iconName: 'home' },
      { label: '登出', func: logoutHandler, iconName: 'exitToApp' }
    ];
    const countyList = [
      { label: '回到班級', func: goToHome, iconName: 'home' },
      { label: '學習報告', func: () => history.push(COUNTY_CMS_URL), iconName: 'widgets' },
      { label: '登出', func: logoutHandler, iconName: 'exitToApp' }
    ];
    const list = hasCountyCmsPermission ? countyList : companyList;
    return list;
  };




  return (
    <UiAvatarMenu>
      {
        profile && profile.id && (
          <ClickablePopoverMenu menuList={popoverMenuList()}>
            <UiButtonWrapper>
              <MatIconButton color="inherit">
                <MatAvatar src={profile.thumbnailUrl || ''} alt={profile.nickname} />
              </MatIconButton>
              {isCompany && <UiNickname>{profile.nickname}</UiNickname>}
            </UiButtonWrapper>
          </ClickablePopoverMenu>
        )
      }
    </UiAvatarMenu>
  );
};

AvatarMenu.propTypes = {
  userId: PropTypes.string
};
