export const COMMON_ACTION_TYPE = {
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE'
};

export const ROLES = {
  ADMIN: 'admin',
  USER: 'user'
};

export const FREE_GROUP_ROLES = {
  HOST_CANDIDATE: 'hostCandidate',
  USER: 'user'
};

export const HomeworkCardStatus = {
  EDIT: 'edit',
  READ: 'read'
};
export const AnnouncementCardStatus = {
  EDIT: 'edit',
  READ: 'read'
};

export const PostCardStatus = {
  EDIT: 'edit',
  READ: 'read'
};

export const EDIT_STATUS = {
  CREATE: 'create',
  EDIT: 'edit',
  READ: 'read',
  ALIGN_TOP: 'verticalAlignTop',
  SUBMIT: 'submit',
  COPY : 'copy'
};

export const SUBMIT_STATUS = {
  submitted: '已繳交',
  unsubmitted: '未繳交',
  lateSubmit: '遲交',
};

export const FORGETPASSWORD = 'forgetPassword';

export const REGISTER = 'register';

export const RULE = 'rule';

export const EXAM_PROJECT_NAME = 'onelink';

export const COMPANY_TITLE = 'OneClass for School';

// 目前沒用到
// export const PERMISSION_GROUPS = {
//   OWNER: 'owner',
//   SESSION_REPORT_MANAGEMENT: 'session_report_management',
//   SESSION_MANAGEMENT: 'session_management',
//   ORGANIZATION_SETTING_MANAGEMENT: 'organization_setting_management',
//   RESOURCE_MANAGEMENT: 'resource_management',
//   STAFF_PERMISSION_MANAGEMENT: 'staff_permission_management',
//   STAFF_MANAGEMENT: 'staff_management',
//   SESSION_MONITORING_MANAGEMENT: 'session_monitoring_management',
//   CUSTOMER_MANAGEMENT: 'customer_management',
//   GROUP_MANAGEMENT: 'group_management'
// };

export const PERMISSIONS = {
  // session
  VIEW_SESSION_REPORT: 'view_session_report',
  MANAGE_SESSION: 'manage_session',
  MONITOR_SESSION: 'monitor_session',

  // organization
  VIEW_ORGANIZATION_SETTING: 'view_organization_setting',
  EDIT_ORGANIZATION_SETTING: 'edit_organization_setting',

  // resource
  VIEW_RESOURCE: 'view_resource',
  MANAGE_RESOURCE: 'manage_resource',

  // staff
  MANAGE_STAFF_PERMISSION: 'manage_staff_permission',
  VIEW_STAFF_PROFILE: 'view_staff_profile',
  ADD_STAFF: 'add_staff',
  EDIT_STAFF_PROFILE: 'edit_staff_profile',

  // customer
  VIEW_CUSTOMER_PROFILE: 'view_customer_profile',
  INVITE_CUSTOMER: 'invite_customer',
  EDIT_CUSTOMER_PROFILE: 'edit_customer_profile',

  // group
  VIEW_GROUP: 'view_group',
  MANAGE_GROUP: 'manage_group',

  // 科目權限
  VIEW_CUSTOM_EDUCATION: 'view_custom_education_list',
  MANAGE_CUSTOM_EDUCATION: 'manage_custom_education_list',
};


export const DASHBOARD_SUMMARY_CATEGORY = {
  GROUP: 'group',
  STAFF: 'staff',
  CUSTOMER: 'customer',
  RESOURCE: 'resource',
  USER: 'user',
  HOSTCANDIDATE: 'hostCandidate',
  ANNOUNCEMENT: 'announcement',
  MISSION: 'mission',
  POST: 'post',
  SESSION: 'session'
};


export const ATTENDANCE_STATUS = {
  LATE: 'late',
  LEAVE_EARLY: 'leave_early',
  ON_TIME: 'on_time',
  ABSENCE: 'absence'
};

// 學制
export const EDUCATION_LEVEL = {
  ELEMENTARY: 'E',
  JUNIOR: 'J',
  SENIOR: 'H',
  OTHER: 'other'
};

// 年級
export const EDUCATION = {
  [EDUCATION_LEVEL.ELEMENTARY]: [
    { label: '1 年級', value: '1' },
    { label: '2 年級', value: '2' },
    { label: '3 年級', value: '3' },
    { label: '4 年級', value: '4' },
    { label: '5 年級', value: '5' },
    { label: '6 年級', value: '6' },
  ],
  [EDUCATION_LEVEL.JUNIOR]: [
    { label: '7 年級', value: '7' },
    { label: '8 年級', value: '8' },
    { label: '9 年級', value: '9' },
  ],
  [EDUCATION_LEVEL.SENIOR]: [
    { label: '10 年級', value: '10' },
    { label: '11 年級', value: '11' },
    { label: '12 年級', value: '12' },
  ],
};


export const SESSION_START_TIME_TYPE = {
  YET: 'YET',
  ALREADY: 'ALREADY',
  PROCESSING: 'PROCESSING'
};

export const second = 1000;
export const minute = 60 * second;
export const hours = 60 * minute;
export const day = 24 * hours;

export const PREVIEW_TIMESTAMP = 10 * minute; // 預課時間，10分鐘

export const setPremiumStatus = {
  pending: '申請中',
  trial: '體驗版',
  approved: '已開通',
  expired: '已過期',
};


export const ORGANIZATION_SETTINGS_VALUE ={
  ALLOW: 'allow',
  DISALLOW: 'disallow',
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  PRIVATE: 'private',
  PUBLIC: 'public',
  NONE:'none',
  HOST:'host',
  DEFAULT:'default',
  CUSTOM:'custom',
  DISABLED:'disabled',
  ENABLED:'enabled'
};

// 不含國碼電話號碼長度
export const PHONE_LENGTH_NOT_INCLUDE_COUNTY_CODE = 8;

export const SUBJECT = {
  CH: 'CH',
  CN: 'CN',
  EN: 'EN',
  MA: 'MA',
  NA: 'NA',
  SO: 'SO',
  LI: 'LI',
  PE: 'PE',
  PC: 'PC',
  BI: 'BI',
  PY: 'PY',
  EA: 'EA',
  GE: 'GE',
  HI: 'HI',
  CT: 'CT',
  CO: 'CO',
  TC: 'TC',
  EW: 'EW',
  PH: 'PH',
  CE: 'CE',
  CS: 'CS',
};

export const SUBJECT_NAME_MAPPING = {
  [SUBJECT.CH]: '國語',
  [SUBJECT.CN]: '華語',
  [SUBJECT.EN]: '英語',
  [SUBJECT.MA]: '數學',
  [SUBJECT.NA]: '自然與生活科技',
  [SUBJECT.SO]: '社會',
  [SUBJECT.LI]: '生活',
  [SUBJECT.PE]: '健康與體育',
  [SUBJECT.PC]: '國文',
  [SUBJECT.BI]: '生物',
  [SUBJECT.PY]: '理化',
  [SUBJECT.EA]: '地球科學',
  [SUBJECT.GE]: '地理',
  [SUBJECT.HI]: '歷史',
  [SUBJECT.CT]: '公民',
  [SUBJECT.CO]: '綜合活動',
  [SUBJECT.TC]: '科技',
  [SUBJECT.EW]: '英文',
  [SUBJECT.PH]: '物理',
  [SUBJECT.CE]: '化學',
  [SUBJECT.CS]: '公民與社會',
};

export const SUBJECT_NAME_TO_CODE_FOR_SUBJECT_PERMISSION = {
  '國': SUBJECT.CH,
  '英': SUBJECT.EN,
  '數': SUBJECT.MA,
  '自': SUBJECT.NA,
  '社': SUBJECT.SO,
};

export const GRADE_CHINESE_CHAR_MAP = {
  '1': 'ㄧ',
  '2': '二',
  '3': '三',
  '4': '四',
  '5': '五',
  '6': '六',
  '7': '七',
  '8': '八',
  '9': '九',
};