import { CountyCmsAvatarMenu } from 'components';
import React from 'react';
import { useUser } from 'store/user';
import { Box } from '@material-ui/core';
import { UiHeader, UiWelcomeMsg } from './Header.style';

export const Header = () => {
  const [{ profile }] = useUser();
  const name = `${profile?.lastName || ''}${profile?.firstName || ''}`;
  const welcomeMsg = `Hi, ${name}`;
  return (
    <UiHeader>
      <Box>
        <UiWelcomeMsg variant="h6">{welcomeMsg}</UiWelcomeMsg>
      </Box>
      <Box>
        <CountyCmsAvatarMenu />
      </Box>
    </UiHeader>
  );
};
