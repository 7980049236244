import { COUNTY_CMS_PATH } from 'routes/routesConfig/constants';

export const UNIT = {
  SCHOOL: 'school',
  CLASSROOM: 'classroom'
};

export const LEARNING_REPORT_SCHOOLS_URL = `${COUNTY_CMS_PATH.COUNTY_CMS}${COUNTY_CMS_PATH.LEARNING_REPORT}${COUNTY_CMS_PATH.SCHOOLS}`;

export const TOGGLE_BUTTONS = [
  { label: '學校', value: UNIT.SCHOOL },
  { label: '班級', value: UNIT.CLASSROOM }
];

const SEARCH_CONDITION_OPTION_VALUE = {
  COUNTY: 'county',
  CITY: 'city',
  SCHOOL_NAME: 'schoolName',
  CLASSROOM_NAME: 'classroomName',
  ADVISOR_NAME: 'advisorName',
};

export const SEARCH_CONDITION_OPTION = {
  [UNIT.SCHOOL]: [
    {
      label: '縣市',
      value: SEARCH_CONDITION_OPTION_VALUE.COUNTY,
    },
    {
      label: '鄉鎮市區',
      value: SEARCH_CONDITION_OPTION_VALUE.CITY,
    },
    {
      label: '學校名稱',
      value: SEARCH_CONDITION_OPTION_VALUE.SCHOOL_NAME,
    }
  ],
  [UNIT.CLASSROOM]: [
    {
      label: '縣市',
      value: SEARCH_CONDITION_OPTION_VALUE.COUNTY,
    },
    {
      label: '鄉鎮市區',
      value: SEARCH_CONDITION_OPTION_VALUE.CITY,
    },
    {
      label: '學校名稱',
      value: SEARCH_CONDITION_OPTION_VALUE.SCHOOL_NAME,
    },
    {
      label: '班級名稱',
      value: SEARCH_CONDITION_OPTION_VALUE.CLASSROOM_NAME,
    },
    {
      label: '導師姓名',
      value: SEARCH_CONDITION_OPTION_VALUE.ADVISOR_NAME,
    }
  ]
};

export const SEARCH_CONDITION_API_KEY_MAP = {
  [SEARCH_CONDITION_OPTION_VALUE.COUNTY]: 'cityName',
  [SEARCH_CONDITION_OPTION_VALUE.CITY]: 'districtName',
  [SEARCH_CONDITION_OPTION_VALUE.SCHOOL_NAME]: 'officialName',
  [SEARCH_CONDITION_OPTION_VALUE.CLASSROOM_NAME]: 'className',
  [SEARCH_CONDITION_OPTION_VALUE.ADVISOR_NAME]: 'teacherName',
};

// 學校
// const fakeSchools = [
//   {
//     county: '新北市',
//     city: '永和區',
//     name: '秀山國小',
//     organizationHeadcount: '112',
//     users: '64',
//     teacherVideoAssignmentCount: '100',
//     studentVideoWatchCount: '234',
//     testCount: '107',
//     averageScore: '98分',
//   }
// ];
// 班級
// const fakeSchools = [
//   {
//     county: '新北市',
//     city: '永和區',
//     name: '秀山國小',
//     classroomName: '613',
//     grades: '一、二、三、四、五',
//     advisor: '王大陸',
//     classroomHeadcount: '112',
//     users: '64',
//     teacherVideoAssignmentCount: '100',
//     studentVideoWatchCount: '234',
//     testCount: '107',
//     averageScore: '98分'
//   }
// ];
