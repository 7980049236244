import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const UiDateFilter = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  width: '100%',

  '.MuiBox-root': {
    width: '100%',
  },

  '.MuiInputBase-root': {
    borderRadius: '8px',
    minWidth: '144px',
  },
}));