import { DEVICE } from 'constants/countyCms';
import styled from 'styled-components';
import { Box, Container, useMediaQuery } from '@material-ui/core';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

export const UiCountyCmsPage = styled(Container)(() => ({
  flex: 1,
  paddingTop: '32px',
  paddingBottom: '32px'
}));

export const UiMainBox = styled(Box)(() => {
  const isMobile = useMediaQuery(`(max-width:${DEVICE.W_MAX_MOBILE}px)`);
  return {
    background: '#FFF',
    borderRadius: '16px',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',

    '& > .app-bar': {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start': 'center',
      justifyContent: 'space-between',
      gap: '16px',
      background: '#E4F6FA',
      boxShadow: 'unset',
      padding: '24px',

      '> .title-tabs-box': {
        display: 'flex',
        alignItems: 'center',

        '> .tabs-box': {
          '.MuiTabScrollButton-root': {
            color: '#637381',
          },
        },

        '> .title-box': {
          whiteSpace: 'nowrap',
          '.title': {
            color: '#212B36',
            fontSize: '24px',
            fontWeight: 500
          }
        }
      },

      '.export-button-box': {
        width: isMobile ? '100%' : 'unset',
        whiteSpace: 'nowrap',
        '> .MuiButton-root': {
          color: '#318193',
        }
      }
    }
  };
});

export const UiTabList = styled(TabList)(() => ({
  '.MuiTabs-flexContainer': {
    gap: '32px',
    padding: '0 24px',

    '& > .MuiTab-root': {
      color: '#637381',
      fontSize: '18px',
      fontWeight: 500,
      minWidth: 'unset',
      padding: 'unset'
    },
    '& > .Mui-selected': {
      color: '#307F90'
    }
  },

  '.MuiTabs-indicator': {
    background: '#307F90',
    transform: 'translateX("50%")'
  }
}));

export const UiTabPanel = styled(TabPanel)(() => ({
  padding: 'unset'
}));
