import { Box, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { Icon } from 'components';
import PropTypes from 'prop-types';
import { UiCountyCmsTotalCard } from './CountyCmsTotalCard.style';

export const CountyCmsTotalCard = ({
  iconSvgName = 'NoteAlt',
  title = '',
  total = 0,
  bottomText = '',
}) => {

  const currencyText = new Intl.NumberFormat('zh-tw').format(total);
  return (
    <UiCountyCmsTotalCard>
      <Box className='icon-box'>
        <Icon.Svg name={iconSvgName} color="#FFF" size='60px' />
      </Box>
      <Box className="label-total-box">
        <Box>
          <Typography className="label" component='span'>{title}</Typography>
        </Box>
        <Box>
          <Typography className="total" component='span'>{currencyText}</Typography>
        </Box>
      </Box>
      <Box className="divider-box">
        <Divider />
      </Box>
      <Box className="last-time-box">
        <Box>
          <Typography className="last-time" component='span'>{bottomText}</Typography>
        </Box>
      </Box>
    </UiCountyCmsTotalCard>
  );
};

CountyCmsTotalCard.propTypes = {
  iconSvgName: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.string || PropTypes.number,
  bottomText: PropTypes.number,
};