import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const UiCountyCmsLayout = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  background: '#F2F4F8'
}));

export const UiCountyCmsChildrenBox = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));