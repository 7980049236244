import styled from 'styled-components';
import { Box, useMediaQuery } from '@material-ui/core';
import { DEVICE } from 'constants/countyCms';

export const UiKeywordFilter = styled(Box)(() => {
  const isMobile = useMediaQuery(`(max-width:${DEVICE.W_MAX_MOBILE}px)`);
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    width: '100%',

    '.MuiInputBase-root': {
      borderRadius: '8px',
      minWidth: '144px',
    },

    '.select-box': {
      flex: 1,
      maxWidth: isMobile ? 'unset' : '200px',
      // '.MuiFormControl-root': {
      // }
    },

    '.keyword-box': {
      maxWidth: isMobile ? 'unset' : '200px',
      flex: 1,
      // '.MuiFormControl-root': {
      // }
    },
  };
});