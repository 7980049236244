
export const COMMON_PATH = {
  LOGIN: '/',
  ENTRANCE: '/entrance',
};

export const COUNTY_CMS_PATH = {
  COUNTY_CMS: '/county-cms',
  LEARNING_REPORT: '/learning-report',
  ORGANIZATIONS: '/organizations',
  SCHOOLS: '/schools',
  STUDENTS: '/students',
};