import React from 'react';
import PropTypes from 'prop-types';
import { IconButton as MatIconButton } from '@material-ui/core';
import { useUser } from 'store/user';
import { ClickablePopoverMenu } from 'components';
import { useHistory } from 'react-router-dom';
import {
  UiAvatarMenu,
  UiButtonWrapper,
  UiAvatar
} from './CountyCmsAvatarMenu.style';

/**
 * 學習報告 用頭像組件
 */
export const CountyCmsAvatarMenu = () => {
  const history = useHistory();
  const [{ profile }, { userLogout }] = useUser();
  const logoutHandler = () => {
    userLogout();
  };

  /* 回到 OMS */
  const goToOms = () => {
    const organizationId = profile?.organizations?.[0] || null;
    const url = organizationId ? `/organization/${organizationId}` : '/home';
    history.push(url);
  };

  const popoverMenuList = () => {
    const list = [
      { label: '回到 OMS', func: goToOms},
      { label: '登出', func: logoutHandler }
    ];
    return list;
  };

  return (
    <UiAvatarMenu>
      <ClickablePopoverMenu menuList={popoverMenuList()}>
        <UiButtonWrapper>
          <MatIconButton color="inherit">
            <UiAvatar src={profile.thumbnailUrl || ''} alt={profile.nickname} />
          </MatIconButton>
        </UiButtonWrapper>
      </ClickablePopoverMenu>
    </UiAvatarMenu>
  );
};

CountyCmsAvatarMenu.propTypes = {
  userId: PropTypes.string
};
