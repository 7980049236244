import { DEVICE } from 'constants/countyCms';
import styled from 'styled-components';
import { Box, useMediaQuery } from '@material-ui/core';

export const UiCountyCmsFilters = styled(Box)(() => {
  const isTablet = useMediaQuery(`(max-width:${DEVICE.W_MAX_TABLET}px)`);
  const isMobile = useMediaQuery(`(max-width:${DEVICE.W_MAX_MOBILE}px)`);
  return {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
    flexWrap: isTablet ? 'wrap' : 'no-wrap',
    gap: '20px',
    width: isTablet ? '100%' : 'unset',

    '.filter-component-box': {
      display: 'flex',
      flexDirection: 'row',
      gap: '20px',
      width: '100%',
      flex: isTablet ? 1 : 'unset',
      '& > .divider-box': {
        display: isMobile ? 'none' : 'contents',
      },
    },

    '.search-button-box': {
      flex: isTablet ? 1 : 'unset',
      width: isTablet ? '100%' : 'unset',
      whiteSpace: 'nowrap',
    }
  };
});
